import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { colorConfig } from './../../config/globalConfig'


const Wrapper = styled.div`
  font-family: 'Nunito Sans',arial;

  border: 1px #000 solid;
  background: white;
  padding: 0px 0px;
  height: 32px;
  max-width: 150px;
  input {
    border: none;
    font-family: 'Nunito Sans',arial;
    outline: none !important;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    color: ${props => (props.color || '#000' )};
  }
  .prefix {
    position: absolute;
    top: 0px;
    left: 10px;
    font-weight: 600;
    font-size: 1rem;
    height: 30px;
    line-height: 35px;
    color: ${props => props.color || '#000'};
  }
  .postfix {
    position: absolute;
    top: 0px;
    right: 5px;
    font-weight: 600;
    font-size: 1rem;
  }
`;

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.isFocused = false;
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.convertToCurrency = this.convertToCurrency.bind(this);
    this.convertCurrencyToNum = this.convertCurrencyToNum.bind(this);
  }
  onFocus() {
    this.isFocused = true;
    if (this.props.type === 'currency') { this.convertCurrencyToNum(); }
  }
  onBlur() {
    this.isFocused = false;
    if (this.props.type === 'currency') { this.convertToCurrency(); }
  }
  componentDidMount() {
    this.input.value = typeof this.props.value === 'undefined' ? '' : this.props.value;
    if (this.props.type === 'currency') return this.convertToCurrency();
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (!this.isFocused) {
        this.input.value = typeof this.props.value === 'undefined' ? '' : this.props.value;
      }
    }
  }
  handleKeyPress(e) {
    if (e.which === 13 && typeof this.props.onEnter === 'function') {
      this.props.onEnter();
    }
    if (e.which === 27 && typeof this.props.onEsc === 'function') {
      this.props.onEsc();
    }
  }
  convertToCurrency() {
    const val = this.input.value.toString().replace(/([a-zA-Z]|,)/g, '');
    if (!isNaN(val) && !isNaN(parseInt(val, 10))) {
      this.input.value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      this.input.value = 0;
    }
  }
  convertCurrencyToNum() {
    this.input.value = this.input.value.toString().replace(/([a-zA-Z]|,)/g, '');
  }
  render() {
    const stylePrefixes = {};
    const stylePostfix = {};
    const styleInput = {
      paddingLeft: this.props.prefixes ? '20px' : '',
      paddingRight: this.props.postfix ? '20px' : ''
    };
    const styleObj = Object.assign({}, this.props.style, styleInput);

    const onChange = (e) => {
      this.props.onChange(e.target.value);
    };
    const safeValue = typeof this.props.value !== 'undefined' ? this.props.value : '';
    const styleWrap = { position: 'relative', width: this.props.width || '100%' };
    if (this.props.width) {
      styleObj.maxWidth = (this.props.type === 'currency') ? this.props.width - 35 : this.props.width - 10;
    }
    return (
      <Wrapper border={this.props.border} color={this.props.color} >
        <div style={styleWrap}>
          {this.props.prefixes ? <div className='prefix' style={stylePrefixes} >{this.props.prefixes}</div> : ''}
          <input
            id={this.props.id}
            maxLength={this.props.maxLength}
            ref={(input) => { this.input = input; }}
            onChange={onChange}
            onKeyPress={this.handleKeyPress}
            readOnly={this.props.readOnly}
            placeholder={this.props.placeholder}
            className='form-control'
            onFocus={this.onFocus} onBlur={this.onBlur}
            style={styleObj}
            defaultValue={safeValue}
            type={this.props.type ? this.props.type : 'text'}
            min={this.props.min}
            max={this.props.max}
          />
          {this.props.postfix ? <div className='postfix'  style={stylePostfix} >{this.props.postfix}</div> : ''}
        </div>
        {this.props.children ? <div className='block-input__value-format-tip' >{this.props.children}</div> : ''}
      </Wrapper>
    );
  }
}

TextInput.propTypes = {
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

export default TextInput;
