import React from 'react';
import styled from 'styled-components';

const InfoIconWrap = styled.div`
    position: relative;
    display: inline-block;
    top: 5px;
    left: 5px;
    img { width: 23px; height: 22px; }
`;

export const InfoIcon = () => {
    // const color = "#6c7286";
    // const size = 20;
    return (
      <InfoIconWrap>
          <svg viewBox="0 0 100 100" style={{ width: '20px', height: '20px', marginLeft: '5px' }}><path fill={"#4c4c4c"} d={"M50.433,0.892c-27.119,0-49.102,21.983-49.102,49.102s21.983,49.103,49.102,49.103s49.101-21.984,49.101-49.103S77.552,0.892,50.433,0.892z M59,79.031C59,83.433,55.194,87,50.5,87S42,83.433,42,79.031V42.469c0-4.401,3.806-7.969,8.5-7.969s8.5,3.568,8.5,7.969V79.031z M50.433,31.214c-5.048,0-9.141-4.092-9.141-9.142c0-5.049,4.092-9.141,9.141-9.141c5.05,0,9.142,4.092,9.142,9.141C59.574,27.122,55.482,31.214,50.433,31.214z"}></path></svg>
      </InfoIconWrap>
    );
}
